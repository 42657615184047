import validate from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/home/runner/work/injective-hub/injective-hub/middleware/maintenance.global.ts";
import manifest_45route_45rule from "/home/runner/work/injective-hub/injective-hub/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  maintenance_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auction-validation": () => import("/home/runner/work/injective-hub/injective-hub/middleware/auction-validation.ts"),
  "proposal-validation": () => import("/home/runner/work/injective-hub/injective-hub/middleware/proposal-validation.ts"),
  "wallet-connected": () => import("/home/runner/work/injective-hub/injective-hub/middleware/wallet-connected.ts")
}