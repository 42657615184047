import { I18nMessageFunction } from '@/types'

export default {
  staking: {
    staking: 'Staking',
    yourStakingStats: 'Your Staking Stats',
    yourStakedAmount: 'Your Staked Amount',
    availableInjInWallet: 'Available INJ In Wallet',
    unbondingsRedelegate: 'Unbondings/Redelegate',
    completionTime: 'Completion Time',
    decentralizationIsACollaborativeEffort:
      'Decentralization is a collaborative effort',
    validatorVotingPowerWarning:
      'You have selected a validator with relatively large voting power. In order to make Injective more decentralized, please consider choosing another validator.',
    chooseOtherValidators: 'Choose other validators',
    continueToDelegate: 'Continue to delegate',
    viewYourStaking: 'View and manage your staking by connecting your wallet.',
    connectWallet: 'Connect Wallet',

    calculateRewards: 'Calculate Rewards',
    rewardsCalculator: 'Staking Rewards Calculator',
    apr: 'apr',
    rewards: 'Staking Rewards',
    delegationWarning: 'Delegation Warning',
    caution: 'Caution',
    noRewards: 'no rewards',
    cancelUnbond: 'Cancel Unbond',
    successfullyCancelledUnbond: 'Successfully Cancelled Unbond',
    delegationWarningMessage: ({ named, interpolate }: I18nMessageFunction) =>
      interpolate([
        named('caution'),
        ': ',
        'Please be advised that delegating to a validator with a 100% commission rate will result in ',
        named('noRewards'),
        ' being issued to you. If you delegate to a 100% commission rate validator, you will receive ',
        named('noRewards'),
        '. As a result, we strongly advise against delegating to such validators.'
      ]),
    delegateAnyway: 'Delegate Anyway',
    dailyReturns: 'Daily Returns',
    monthlyReturns: 'Monthly Returns',
    yearlyReturns: 'Yearly Returns',
    yourStake: 'Your Injective (INJ) Stake',
    searchForValidators: 'Search for Validators',
    stakingOverview: 'Your Staking Overview',
    validatorsLimitReached:
      'Please be aware that you are now delegating to more than 10 validators. Certain features in the Injective ecosystem will only account for staked INJ in the first 10 validators.',
    redeem: 'Redeem',
    voucherRedeemed: 'Voucher Redeemed',
    voucherRedeemedDescription:
      'You have successfully redeemed your voucher. Please check your wallet for the corresponding INJ.',
    enterVoucherCode: 'Enter voucher code',

    redeemSuccessDescription:
      "You have now successfully enrolled in the Staking Rebate Campaign. Please select the validator indicated on your voucher to 'delegate' or 're-delegate' and complete your participation before October 1."
  }
}
