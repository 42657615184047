import { I18nMessageFunction } from '@/types'

export default {
  insuranceFund: {
    funds: 'Funds',
    market: 'Market',
    redeem: 'Redeem',
    create: 'Create',
    ticker: 'Ticker',
    tickerTooltip: '',
    overview: 'Overview',
    poolTokens: 'tokens',
    quoteDenomTooltip: '',
    oracleTypeTooltip: '',
    oracleBaseTooltip: '',
    disbursed: 'Disbursed',
    oracleQuoteTooltip: '',
    underwrite: 'Underwrite',
    quoteDenom: 'Quote Denom',
    oracleType: 'Oracle Type',
    oracleBase: 'Oracle Base',
    oracleQuote: 'Oracle Quote',
    myPoolTokens: 'My Pool Tokens',
    myUsdtBalance: 'My USDT Balance',
    insuranceFunds: 'Insurance Funds',
    completionTime: 'Completion Time',
    totalPoolTokens: 'Total Pool Tokens',
    redemptionAmount: 'Redemption Amount',
    successRedeem: 'Successfully Redeemed',
    successCreated: 'Successfully Created',
    initialInsuranceFundDepositTooltip: '',
    selectQuoteDenom: 'Select Quote Denom',
    selectOracleType: 'Select Oracle Type',
    selectOracleBase: 'Select Oracle Base',
    requestRedemption: 'Request Redemption',
    totalUnderwritten: 'Total Underwritten',
    selectOracleQuote: 'Select Oracle Quote',
    pendingRedemptions: 'Pending Redemptions',
    insuranceFundToken: 'Insurance Fund Token',
    noResultsFound: 'There are no results found',
    createInsuranceFund: 'Create Insurance Fund',
    enterAmountToRedeem: 'Enter amount to redeem',
    successUnderwrite: 'Successfully Underwritten',
    estimatedDisbursement: 'Estimated Disbursement',
    totalUsdtUnderwritten: 'Total USDT Underwritten',
    myOwnershipPercentage: 'My Ownership Percentage',
    availableForRedeeming: 'Available for Redeeming',
    redemptionNoticePeriod: 'Redemption Notice Period',
    createNewInsuranceFund: 'Create New Insurance Fund',
    enterAmountToUnderwrite: 'Enter amount to underwrite',
    availableForUnderwriting: 'Available for Underwriting',

    availableForRedeemingTooltip:
      'The amount you can redeem from this insurance fund.',
    availableForUnderwritingTooltip:
      'The amount you can underwrite to this insurance fund.',
    redemptionAmountTooltip:
      'The number of tokens redeemed from the insurance pool.',
    completionTimeTooltip:
      'The amount of time you have to wait before your deposit is redeemed.',
    estimatedDisbursementTooltip:
      'The estimated amount you are going to get after the redemption is disbursed.',

    minDepositRequirement: ({ named }: I18nMessageFunction) =>
      `You need minimum ${named('min')} ${named(
        'asset'
      )} in your balance to make a proposal`,

    underwriteToInsuranceFund: ({ named }: I18nMessageFunction) =>
      `Underwrite to ${named('fund')} derivative market`,
    requestFromInsuranceFund: ({ named }: I18nMessageFunction) =>
      `Request redemption from ${named('fund')} insurance pool`
  }
}
