import {
  Network,
  isDevnet,
  isMainnet,
  isTestnet,
  getCw20AdapterContractForNetwork
} from '@injectivelabs/networks'

export const NETWORK: Network =
  (import.meta.env.VITE_NETWORK as Network) || Network.Devnet
export const IS_DEVNET = isDevnet(NETWORK)
export const IS_TESTNET = isTestnet(NETWORK)
export const IS_MAINNET = isMainnet(NETWORK)
export const IS_STAGING = NETWORK === Network.Staging

export const CW20_ADAPTER_CONTRACT = getCw20AdapterContractForNetwork(NETWORK)

export const IS_PRODUCTION: boolean = process.env.NODE_ENV === 'production'
export const BASE_URL: string =
  process.env.VITE_BASE_URL || 'http://localhost:3000'

export const MAINTENANCE_ENABLED =
  import.meta.env.VITE_MAINTENANCE_ENABLED === 'true'
