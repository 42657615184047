export enum Currency {
  Chf = 'chf',
  Eur = 'eur',
  Gbp = 'gbp',
  Usd = 'usd'
}

export interface SingleCurrency {
  code: Currency
  symbol: string
  decimals: number
}

export interface PriceByCurrency {
  chf: string | number
  eur: string | number
  gbp: string | number
  usd: string | number
}
