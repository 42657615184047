import { default as _91_91auction_93_93PKn0gsLweUMeta } from "/home/runner/work/injective-hub/injective-hub/pages/auction/[[auction]].vue?macro=true";
import { default as bridge7vCcfJMpegMeta } from "/home/runner/work/injective-hub/injective-hub/pages/bridge.vue?macro=true";
import { default as envlljJMAHA51Meta } from "/home/runner/work/injective-hub/injective-hub/pages/env.vue?macro=true";
import { default as external_45linkMo0rZHFkiyMeta } from "/home/runner/work/injective-hub/injective-hub/pages/external-link.vue?macro=true";
import { default as faqwuViR8XtrWMeta } from "/home/runner/work/injective-hub/injective-hub/pages/faq.vue?macro=true";
import { default as governancewzO1H0tgnAMeta } from "/home/runner/work/injective-hub/injective-hub/pages/governance.vue?macro=true";
import { default as indexZqF1VbDmiUMeta } from "/home/runner/work/injective-hub/injective-hub/pages/index.vue?macro=true";
import { default as insurance_45fund7nJgWeTDH7Meta } from "/home/runner/work/injective-hub/injective-hub/pages/insurance-fund.vue?macro=true";
import { default as maintenance2vnBd8a3syMeta } from "/home/runner/work/injective-hub/injective-hub/pages/maintenance.vue?macro=true";
import { default as _91proposal_93rLBfdLQYJ7Meta } from "/home/runner/work/injective-hub/injective-hub/pages/proposal/[proposal].vue?macro=true";
import { default as _91_91type_93_93o0ZVPKpgmLMeta } from "/home/runner/work/injective-hub/injective-hub/pages/proposal/create/[[type]].vue?macro=true";
import { default as stakingQCPxWTHiKIMeta } from "/home/runner/work/injective-hub/injective-hub/pages/staking.vue?macro=true";
import { default as token_45factoryS9MZkVDg6EMeta } from "/home/runner/work/injective-hub/injective-hub/pages/token-factory.vue?macro=true";
import { default as _91validator_93PLwYToDdt0Meta } from "/home/runner/work/injective-hub/injective-hub/pages/validators/[validator].vue?macro=true";
import { default as walletmXBmMXqIc2Meta } from "/home/runner/work/injective-hub/injective-hub/pages/wallet.vue?macro=true";
export default [
  {
    name: "auction-auction",
    path: "/auction/:auction?",
    meta: _91_91auction_93_93PKn0gsLweUMeta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/auction/[[auction]].vue")
  },
  {
    name: "bridge",
    path: "/bridge",
    meta: bridge7vCcfJMpegMeta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/bridge.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envlljJMAHA51Meta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/env.vue")
  },
  {
    name: "external-link",
    path: "/external-link",
    meta: external_45linkMo0rZHFkiyMeta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/external-link.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/faq.vue")
  },
  {
    name: "governance",
    path: "/governance",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/governance.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/index.vue")
  },
  {
    name: "insurance-fund",
    path: "/insurance-fund",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/insurance-fund.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenance2vnBd8a3syMeta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/maintenance.vue")
  },
  {
    name: "proposal-proposal",
    path: "/proposal/:proposal()",
    meta: _91proposal_93rLBfdLQYJ7Meta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/proposal/[proposal].vue")
  },
  {
    name: "proposal-create-type",
    path: "/proposal/create/:type?",
    meta: _91_91type_93_93o0ZVPKpgmLMeta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/proposal/create/[[type]].vue")
  },
  {
    name: "staking",
    path: "/staking",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/staking.vue")
  },
  {
    name: "token-factory",
    path: "/token-factory",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/token-factory.vue")
  },
  {
    name: "validators-validator",
    path: "/validators/:validator()",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/validators/[validator].vue")
  },
  {
    name: "wallet",
    path: "/wallet",
    meta: walletmXBmMXqIc2Meta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/wallet.vue")
  },
  {
    name: "proposals-proposal",
    path: "/proposals/:proposal?",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/governance.vue")
  },
  {
    name: "proposals",
    path: "/proposals",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/governance.vue")
  }
]