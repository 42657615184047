import { Currency, SingleCurrency } from '@/types/currency'

export const USD: SingleCurrency = {
  code: Currency.Usd,
  symbol: '$',
  decimals: 2
}

export const EUR: SingleCurrency = {
  code: Currency.Eur,
  symbol: '€',
  decimals: 2
}

export const GBP: SingleCurrency = {
  code: Currency.Gbp,
  symbol: '£',
  decimals: 2
}

export const CHF: SingleCurrency = {
  code: Currency.Chf,
  symbol: 'Fr',
  decimals: 2
}

export const currencies = {
  USD,
  EUR,
  CHF,
  GBP
}
