export enum TransferOnChainField {
  Memo = 'memo',
  Denom = 'denom',
  Amount = 'amount',
  Destination = 'destination',
  InjectiveAddress = 'injectiveAddress'
}

export enum TokenFactorySupplyField {
  Amount = 'amount'
}

export enum TokenFactoryDenomField {
  SubDenom = 'subdenom',
  Name = 'name',
  Symbol = 'symbol'
}

export enum TokenFactoryMetadataField {
  URI = 'uri',
  Name = 'name',
  Symbol = 'symbol',
  Display = 'display',
  Exponent = 'exponent',
  Description = 'description'
}

export enum AuctionPoolField {
  Denom = 'denom',
  Amount = 'amount'
}

export type AuctionPoolForm = {
  [AuctionPoolField.Denom]: string
  [AuctionPoolField.Amount]: string
}

export type TransferOnChainForm = {
  [TransferOnChainField.Memo]: string
  [TransferOnChainField.Denom]: string
  [TransferOnChainField.Amount]: string
  [TransferOnChainField.Destination]: string
}

export type TokenFactorySupplyForm = {
  [TokenFactorySupplyField.Amount]: string
}

export type TokenFactoryDenomForm = {
  [TokenFactoryDenomField.SubDenom]: string
}

export type TokenFactoryMetadataForm = {
  [TokenFactoryMetadataField.URI]: string
  [TokenFactoryMetadataField.Name]: string
  [TokenFactoryMetadataField.Symbol]: string
  [TokenFactoryMetadataField.Display]: string
  [TokenFactoryMetadataField.Exponent]: string
  [TokenFactoryMetadataField.Description]: string
}
