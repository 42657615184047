export enum MainPage {
  Faq = 'faq',
  Wallet = 'wallet',
  Index = 'index',
  Staking = 'staking',
  Governance = 'governance',
  Auction = 'auction-auction',
  TokenFactory = 'token-factory',
  Proposal = 'proposal-proposal',
  InsuranceFund = 'insurance-fund',
  Validator = 'validators-validator',
  ProposalCreateType = 'proposal-create-type'
}
