import { MAINTENANCE_ENABLED } from '@/app/utils/constant'

const maintenanceRouteName = 'maintenance'

export default defineNuxtRouteMiddleware((to) => {
  if (to.name !== maintenanceRouteName && MAINTENANCE_ENABLED) {
    return navigateTo('/maintenance')
  }

  if (to.name === maintenanceRouteName && !MAINTENANCE_ENABLED) {
    return navigateTo('/')
  }
})
