import { I18nMessageFunction } from '@/types'

export default {
  proposal: {
    title: 'Proposal',
    status: {
      '0': 'All',
      '1': 'Deposit',
      '2': 'Voting',
      '3': 'Passed',
      '4': 'Rejected',
      '5': 'Failed'
    },
    empty: 'No proposal found.',
    totalProposals: 'Total Proposals',
    passedProposals: 'Passed Proposals',
    depositRequired: 'Deposit Required',
    deposit: {
      depositor: 'Depositor',
      depositors: 'Depositors',
      amountToDeposit: 'The amount you want to deposit',
      periodHasEnded:
        'Deposit period has ended, the proposal is now in the voting stage',
      successfullyDeposited: 'Successfully Deposited'
    },
    vote: {
      title: 'Vote',
      voter: 'Voter',
      voters: 'Voters',
      voteOption: 'Vote Option',
      successfullyVoted: 'Successfully Voted',
      notInVotingState: 'This proposal is not in voting state',
      alreadyVoted: 'You already voted for this proposal',
      periodHasEnded: 'Voting period has ended',
      power: 'Voting Power',
      powerTooltip:
        'The voting power that the particular validator holds for governance purposes.',
      option: {
        '1': 'Yes',
        '2': 'Abstain',
        '3': 'No',
        '4': 'No With Veto',
        yes: 'Yes',
        no: 'No',
        abstain: 'Abstain',
        noWithVeto: 'No With Veto'
      }
    },
    proposalId: 'Proposal ID',
    detailsPropProposalId: ({ named }: I18nMessageFunction) =>
      `Proposal Details - Proposal ${named('proposalId')}`,
    proposedDate: ({ named }: I18nMessageFunction) =>
      `Proposed ${named('date')}`,

    'Voting ends': 'Voting ends',
    result: 'Result',

    votingEnds: 'Voting ends',
    closingIn: 'Closing in ',
    closingOn: 'Closing on ',
    closed: 'Closed',
    viewJSON: 'View JSON',
    depositEnds: 'Deposit ends',
    'Successfully Voted': 'Successfully Voted',
    'This proposal is not in voting state':
      'This proposal is not in voting state',
    'You already voted for this proposal':
      'You already voted for this proposal',
    tallying: {
      title: 'Tallying Procedure',
      quorum: {
        title: 'Quorum',
        tooltip: ({ named }: I18nMessageFunction) =>
          `Quorum is the minimum amount of votes that need to be cast for the proposal result to be valid. Currently, ${named(
            'percentage'
          )} is the minimum amount of voter turnout needed to reach quorum.`,
        reached: 'Quorum has been Reached'
      },
      threshold: {
        title: 'Threshold',
        tooltip: ({ named }: I18nMessageFunction) =>
          `Threshold is defined as the minimum proportion of Yes votes (excluding Abstain votes) for the proposal to be accepted. If the proportion of Yes votes at the end of the voting period is higher than 50% and at the same time the higher of No with Veto is lower than ${named(
            'percentage'
          )}, the proposal will pass.`
      },
      voting: {
        title: 'Voting Period',
        tooltip: ({ named }: I18nMessageFunction) =>
          `Participants in the governance procedure are INJ holders, those who have staked INJ tokens have the right to vote on proposals. The voting period is defined as the interval between the moment the vote opens and the moment the vote closes. The current voting period is set to ${named(
            'duration'
          )}.`
      }
    }
  },

  governance: {
    title: 'Title',
    description: 'Description',
    newProposal: 'New Proposal',
    searchForProposals: 'Search for Proposals',

    common: {
      deposit: 'Deposit',
      deposits: 'Deposits'
    },

    proposal: {
      title: 'Proposal Title',
      description: 'Proposal Description',
      fee: 'Fee',
      ticker: 'Ticker',
      baseDenom: 'Base Denom',
      quoteDenom: 'Quote Denom',
      minNotional: 'Min. Notional',
      minNotionalTooltip:
        'Defines the minimum value that an order must have, in quote, to be placed on the Injective exchange.',
      minNotionalDescription:
        'For example, USDT quoted markets is 1 USDT and INJ quoted markets is 0.01 INJ',
      minQuantityTickSize: 'Min. Quantity Tick Size',
      minQuantityTickSizeDescription:
        'For example, INJ/USDT min. quantity tick size is 0.001. Thus the min amount to trade is 0.001 INJ',
      minPriceTickSize: 'Min. Price Tick Size',
      minPriceTickSizeDescription:
        'For example, INJ/USDT min. price tick size is 0.001. Thus, the INJ price in USDT has 3 decimal places',
      oracleQuote: 'Oracle Quote',
      oracleType: 'Oracle Type',
      oracleScaleFactor: 'Oracle Scale Factor',
      oracleBase: 'Oracle Base',
      initialMarginRatio: 'Initial Margin Ratio',
      maintenanceMarginRatio: 'Maintenance Margin Ratio',
      makerFeeRate: 'Maker Fee Rate',
      takerFeeRate: 'Taker Fee Rate',
      spotInstantLaunchFeeTooltip:
        'The fee required to launch a spot market pair instantly',
      tickerTooltip:
        'The ticker for your proposed market. For example: BTC/USDC.',
      insuranceFundTickerTooltip:
        'The ticker for the insurance fund that will underwrite your derivative proposal.',
      selectBaseDenom: 'Select Base Denom',
      selectQuoteDenom: 'Select Quote Denom',
      baseDenomTooltip: 'This is the asset you would like to trade.',
      quoteDenomTooltip:
        'This is the quote denom that will be used for denominating and handling funds in the market and insurance fund (common examples include USDC or USDT).',
      minPriceTickSizeTooltip:
        "Defines the minimum tick size of the order's price and margin.",
      minQuantityTickSizeTooltip:
        "Defines the minimum tick size of the order's quantity.",
      initialDepositTooltip:
        'The initial deposit requirement for the proposal.',
      initialInsuranceFundDepositTooltip:
        'The initial deposit requirement for the insurance fund.',
      minimumProposalDepositRequired:
        'You need minimum 1 INJ in your balance to make a proposal',
      selectOracleType: 'Select Oracle Type',
      oracleTypeTooltip:
        'The type of the oracle from which the asset price is fetched and will be used to determine for which market the insurance fund will be used, e.g., Band, Coinbase or PriceFeeder (Binance).',
      selectOracleQuote: 'Oracle Quote',
      selectOracleBase: 'Oracle Base',
      oracleQuoteTooltip:
        'Select the oracle quote that will be used to determine for which market the insurance fund will be used. It should match the quote denom.',
      oracleBaseTooltip:
        'Select the oracle base that will be used to determine for which market the insurance fund will be used.',
      oracleScaleFactorTooltip: 'The scale factor for oracle prices.',
      initialMarginRatioTooltip:
        'The minimum margin ratio when opening a position, e.g., 0.05 would result in 5% of the notional as margin requirement (max 20x leverage).',
      maintenanceMarginRatioTooltip:
        "Once a position's margin falls below this ratio, it can be liquidated.",

      advancedMarketSettings: {
        title: 'Advanced Market Settings',
        parameterValues: 'Parameter Values:',
        relayerFeeShareRate: 'Relayer Fee Share Rate',
        minimalProtocolFeeRate: 'Minimal Protocol Fee Rate',
        formula:
          'takerFeeRate > (minimumProtocolFee - makerFeeRate) / (1 - relayerFeeShare)',
        description1:
          'The Maker Fee Rate and Taker Fee Rate fields are pre-populated with our recommended values to ensure optimal market conditions.',
        description2:
          'The relationship between the Maker Fee Rate and Taker Fee Rate must adhere to the following formula to be valid:',
        description3:
          'Recommendation: We strongly advise against modifying the Maker Fee Rate and Taker Fee Rate unless you are confident in maintaining the correct balance as per the formula. Incorrect adjustments may result in errors and prevent the creation of the market.'
      },

      makerFeeRateTooltip:
        'Describes the target fee rate for makers. Makers create liquidity by setting orders that are not immediately filled.',
      takerFeeRateTooltip:
        ' Describes the target fee rate for takers. Takers fill the orders created by the makers, buying or selling instantly.',

      formType: 'Proposal Type',
      formTypeDescription:
        "Choose the proposal's type which can be any parameter of Injective Protocol or a community-led initiative such as grants programs or liquidity incentive initiatives.",
      formDeposit: 'Deposit information',
      formDepositDescription:
        'For every proposal you create we require you to deposit at least 50 INJ. This is to ensure that you are an active participant of the Injective community and you are eligible to make proposals and govern the protocol moving forward. For the proposal to pass to the voting stage, it must have 100 INJ deposited. You can deposit the 100 INJ yourself or collaborate with the community to deposit them collectively.',
      perpetualProposalGeneral: 'General information',
      perpetualProposalGeneralDescription:
        'Choose a title and description for your proposal, if you have proposed this market informally on the governance forum you can also include the link in the description. Ensure that the ticker is accurate and provide the base asset denom followed by the quote asset denom. Base denom refers to the asset you would like to trade and quote denom refers to the asset by which your asset is denominated. For instance, in the INJ/USDT market you would buy or sell INJ using USDT.',
      perpetualProposalOracle: 'Oracle information',
      perpetualProposalOracleDescription:
        "Choose the oracle type from the available oracle providers. The oracle base and oracle quote should generally match with the base and quote denoms. The decimals field refers to the token's decimals which are 6 for USDT/USDC.",
      perpetualProposalPerpetual: 'Perpetual market information',
      perpetualProposalPerpetualDescription:
        "Choose the market's initial margin, maintenance margin ratios and tick sizes. We recommend to read the FAQ before choosing the minimum prick and quantity tick sizes for context. Makers create liquidity by setting orders that are not immediately filled, while takers buy or sell instantly. In other words, takers fill the orders created by the makers and the maker fee rate is usually set relatively lower to incentive makers to provide further liquidity to the market.",
      spotProposalGeneral: 'General information',
      spotProposalGeneralDescription:
        'Choose a title and description for your proposal, if you have proposed this market informally on the governance forum you can also include the link in the description. Ensure that the ticker is accurate and provide the base asset denom followed by the quote asset denom. Base denom refers to the asset you would like to trade and quote denom refers to the asset by which your base asset is denominated. For instance, in the INJ/USDT market you would buy or sell INJ using USDT.',
      instantSpotGeneralDescription:
        'Ensure that the ticker is accurate and provide the base asset denom followed by the quote asset denom. Base denom refers to the asset you would like to trade and quote denom refers to the asset by which your base asset is denominated. For instance, in the INJ/USDT market you would buy or sell INJ using USDT.',
      instantFee: 'Fee information',
      instantFeeGeneralDescription:
        'An instant listing fee of 20 INJ is required. The fee is sent to the community spend pool. Please make sure all information provided is accurate before launching this market.',
      spotProposalSpot: 'Spot market information',
      spotProposalSpotDescription:
        "Choose the market's base and quote denom and ensure they align with the ticker you chose in the previous step. We recommend to read the FAQ before choosing the minimum prick and quantity tick sizes for context.",
      textProposal: 'Text Proposal',
      textProposalDescription:
        'Propose any action on Injective. TextProposal defines a standard text proposal whose changes need to be manually updated in case of approval.',

      launch: 'Launch',
      propose: 'Propose',
      insufficientBalance: 'Insufficient Balance',
      successfullyProposed: 'Successfully Proposed',
      successfullyLaunched: 'Successfully Launched'
    },

    types: {
      'spot-market-launch': 'Spot Market Launch',
      'perpetual-market-launch': 'Perpetual Market Launch',
      'instant-spot-market-launch': 'Instant Spot Market Launch',
      'text-proposal': 'Text Proposal',

      'oracle-type-1': 'Band',
      'oracle-type-2': 'Pricefeed',
      'oracle-type-3': 'Coinbase',
      'oracle-type-4': 'Chainlink',
      'oracle-type-5': 'Razor',
      'oracle-type-6': 'DIA',
      'oracle-type-7': 'API3',
      'oracle-type-8': 'UMA',
      'oracle-type-9': 'Pyth',
      'oracle-type-10': 'Band IBC'
    }
  },
  discussionForum: 'Discussion Forum'
}
