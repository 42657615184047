import { I18nMessageFunction } from '@/types'

export default {
  whichWallet: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'Which wallets are supported for interacting with Injective?'
      ),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'You can currently connect with MetaMask, Ledger, Keplr, Trezor and Torus'
      ])
  },

  seekAssistance: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'How do I seek for assistance on questions or issues that I encounter?'
      ),
    content: ({ named, interpolate }: I18nMessageFunction) =>
      interpolate([
        'You can contact our team directly on our',
        named('action'),
        'server'
      ]),
    link: ({ interpolate }: I18nMessageFunction) => interpolate('discord')
  },

  stakingWalkthrough: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'Can I find a written walkthrough on how to stake on Mainnet?'
      ),
    content: ({ named, interpolate }: I18nMessageFunction) =>
      interpolate(['Yes, you can find a written walkthrough', named('action')]),
    link: ({ interpolate }: I18nMessageFunction) => interpolate('here')
  },

  injectiveEthereumBridge: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('Does Injective have an Ethereum bridge?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Yes. The Injective Peggy bidirectional bridge allows users to transfer ERC-20 tokens to Injective and ERC-20 tokens back to Ethereum. The bridge is non-custodial and controlled solely by the validators on Injective.'
      ])
  },

  compoundInterest: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('Do I receive compound interest?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "You don't receive compound interest, you will have to claim the rewards and delegate them to validators."
      ])
  },

  unbondStakeFromValidator: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('Can I always unbond my stake from a validator?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "You can always unbond your stake from a validator. If the validator is in the 'Active' or 'Unbonding' state, then you can unbond your stake and have it returned to you after the unbonding period which is set to 21 days. If the validator is unbonded and you unbond your tokens, your stake will be immediately transferred to your balance."
      ])
  },

  delegateMoreTokensToTheSameValidator: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'What happens if I delegate more tokens to the same validator?'
      ),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Your outstanding rewards from that validator will be claimed automatically and transferred to your balance.'
      ])
  },

  reDelegateToAnotherValidator: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What happens if I re-delegate to another validator?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'If you re-delegate to another validator, you are unbonding from the source validator and bonding to the destination validator. If the validator is in the Active or Unbonding state, then you need to wait for the unbonding period before your delegation is delegated to the new validator, if the validator is unbonded, your re-delegation will happen instantly. Note that you continue to earn rewards through the unbonding period when you re-delegate.'
      ])
  },

  unbondMyDelegationFromAValidator: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'What happens with my rewards if I unbond my delegation from a validator?'
      ),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'The rewards will be claimed automatically and the staked amount will be returned to you after the unbonding period.'
      ])
  },

  unbondingUnbondedValidatorRewards: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'What happens with my rewards if the validator goes to the Unbonding/Unbonded state?'
      ),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate(['You can claim the rewards at any time.'])
  },

  whatIsSlashing: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is Slashing?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Slashing is a mechanism built into proof of stake blockchain protocols to discourage validator misbehavior. Slashing is designed to incentivize node security, availability, and network participation. Validator slashing is important for delegators because it affects their delegation as well, the two key misbehaviors that incur slashing are downtime and double signing.'
      ])
  },

  validatorGetsSlashedDelegation: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'What happens with my delegation when a validator gets slashed?'
      ),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'You will lose a certain amount of tokens depending on the slashing event. The validator can get slashed in the Active as well as Unbonding state which means that a validator can continue to get slashed during the unbonding period.'
      ])
  },

  reDelegateStakeToADifferentValidatorAtAnyTime: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'Can I re-delegate my stake to a different validator at any time?'
      ),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Yes, you can re-delegate at any time regardless of the validator status.'
      ])
  },

  whichValidatorShouldIDelegateMyTokensTo: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('Which validator should I delegate my tokens to?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "You should take into account the validator's commission rate, reputation and uptime. One could also delegate to more than one validators in order to diversify holdings"
      ])
  },

  canAValidatorVoteOnMyBehalf: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('Can a validator vote on my behalf?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "If you don't vote as a delegator, your voting weight will be transferred to the validator. If your validator votes before you do, then your vote will replace the validator's vote for your voting weight."
      ])
  },

  validatorCommissionRate: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is the validator commission rate?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "The commission represents the percentage of the delegator's rewards retained by the validator. For example, if this rate is set to 5%, then delegators will receive 95% of the rewards while the validator will receive 5%."
      ])
  },

  validatorMaximumCommissionRate: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is the validator maximum commission rate?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "Validators can change their commission rate at any time and that change will be represented in 24 hours. For instance, if the node validator's maximum rate is set to 15%, then they may change their commission rate up to 15%."
      ])
  },

  validatorMaximumCommissionRateChange: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is the validator maximum commission rate change?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'This rate change defines the maximum daily increase possible. For instance, if the validator has set this parameter to 3% then they can change their commission rate up to 3% within 24 hours. If the maximum commission rate is set to 15% and the maximum commission rate change is set to 3% then the validator can increase it by a maximum of 3% daily up to a maximum of 15%.'
      ])
  },

  onChainGovernance: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is on-chain governance?'),
    content: ({ named, interpolate }: I18nMessageFunction) =>
      interpolate([
        'Injective is a proof-of-stake blockchain governed entirely by the community. Community members coordinate informally off-chain and engage in',
        named('action'),
        'discussions in the governance on improvements or trade-offs and then proceed with formal on-chain proposals.'
      ]),
    link: ({ interpolate }: I18nMessageFunction) => interpolate('forum')
  },

  governanceProposal: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is a governance proposal?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Community makes proposals in order to propose changes to Injective. Proposals include but are not limited to parameter changes, launching new markets, updating markets and software upgrades. Governance proposal can have one of 4 different states at a given time: Deposit, Voting, Passed and Rejected.'
      ])
  },

  depositPeriodForAProposal: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is the deposit period for a proposal?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'In order to prevent spam, proposals require a minDeposit of 500 INJ to pass to the voting stage. Community members often gather together and agree to make deposits collectively for a given proposal. The deposit period is set to 2 days, subject to change through governance'
      ])
  },

  depositsInAProposal: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What happens with the deposits in a proposal?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'When a proposal is finalized (passed or rejected) the INJ coins from the deposit are either refunded or burned based on the final tally of the proposal. If the proposal passes or gets rejected with quorum, the deposits will be automatically refunded to the respective depositors. If the proposal is vetoed or does not reach quorum, the deposits will be burned.'
      ])
  },

  votingPeriodForAProposalAndWhoCanCastAVote: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate(
        'What is the voting period for a proposal and who can cast a vote?'
      ),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "Participants in the governance procedure are INJ holders, those who have staked INJ tokens have the right to vote on proposals whereas INJ holders that haven't staked INJ tokens can submit and deposit in proposals. Once a proposal reaches the minimum deposit required, it immediately enters the voting period. The voting period is defined as the interval between the moment  the vote opens and the moment the vote closes and it's set to 2 days subject to change through governance."
      ])
  },

  kindOfVoteICanCast: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What kind of vote can I cast to a proposal?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "A vote can be Yes, No, Abstain or No with Veto. The Abstain vote allows voters to signal that they don't intend to vote in favor or against the proposal but accept the result of the vote."
      ])
  },

  whatIsQuorum: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is quorum?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Quorum is defined as the minimum percentage of voting power that needs to be casted in a proposal for the result to be valid and represents 33.4% of the total bonded tokens.'
      ])
  },

  thresholdToPass: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is the threshold for a proposal to pass?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Threshold is defined as the minimum proportion of Yes votes (excluding Abstain votes) for the proposal to be accepted. The threshold is set at 50% with a possibility to veto if more than 1/3 of votes (excluding Abstain votes) are No with Veto. If the proportion of Yes votes (excluding Abstain votes) at the end of the voting period is superior to 50% and at the same time the proportion of No with Veto is inferior to 1/3 (excluding Abstain votes) the proposal will pass.'
      ])
  },

  minPriceTickSizeInAProposal: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is the minPriceTickSize in a proposal?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'The minPriceTickSize refers to the minimum price movement of a trading instrument in a given trade. For instance, a value of 0.01 would mean that the price movement of the asset cannot be above 0.01, a price of 0.007 would be rounded up to 0.01.'
      ])
  },

  minQuantityTickSizeInAProposal: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is the minQuantityTickSize in a proposal?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'The minQuantityTickSize refers to the minimum quantity of an asset in a given trade. For instance, a value of 0.1 would mean that the quantity of the asset cannot be above 0.1, a quantity of 0.23 would be rounded up to 0.2.'
      ])
  },

  tickSizesForAPair: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('How to choose the tick sizes for a pair?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'The price of the instrument being traded directly affects the ideal tick sizes and thus they must be adjusted periodically for all assets. As an example, Binance updated the tick size for the INJ/USDT pair from 0.0001 to 0.001 as the price of the base asset increased substantially. This update ultimately improved market liquidity as the trading margins were increased. In order to find the ideal tick sizes, we recommend to look for the asset you intend to propose in well-known exchanges or find an asset that reflects a similar price with the asset you intend to propose to derive the ideal tick sizes.'
      ])
  },

  whyInsuranceFunds: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('Why do we have insurance funds?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        "During periods of high volatility in margin trading, the losing party on a trade might not be able to afford to pay the winning party if they run out of margin, i.e. the position becomes bankrupt. If the trader's position is liquidated and the new trader takes it over at a worse price than the bankruptcy price, the insurance fund is utilized to cover the resulting deficit."
      ])
  },

  insuranceFundGrow: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('How does an insurance fund grow in value?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'The insurance fund grows when a position is liquidated at a price that is BETTER than the bankruptcy price.'
      ])
  },

  fundsFromAnInsuranceFundUsed: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('When are funds from an insurance fund used?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'When the closing price of a trade is WORSE than the bankruptcy price, funds are withdrawn from the insurance fund to pay the winning party.'
      ])
  },

  stakingInAnInsuranceFund: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('How does staking in an insurance fund work?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Unlike most exchanges, the insurance fund on Injective is market-specific. That is, there is no common pool of Insurance capital collected, but rather a new fund exists for each derivative market launched on Injective. This way, the risk for underwriting insurance is isolated to each individual market. When a user underwrites insurance for a derivative market, he stakes the collateral currency for that market and in turn receives insurance pool tokens specific to the market. These pool tokens represent pro-rata (proportional) ownership of the insurance fund. Thus, as an insurance fund grows from liquidation proceeds, the insurance fund stakers gain profit from the increase in value of their stake of the insurance fund.'
      ])
  },

  underwritingForAnInsuranceMarket: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What does underwriting for an insurance market mean?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Underwriting means depositing funds into a specific insurance market. Underwriting for an insurance fund enables you to earn additional staking rewards.'
      ])
  },

  redemptionFromAnInsuranceMarket: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What does redemption from an insurance market mean?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'Redeeming means withdrawing your funds from an insurance fund.'
      ])
  },

  redemptionNoticePeriod: {
    title: ({ interpolate }: I18nMessageFunction) =>
      interpolate('What is the redemption notice period?'),
    content: ({ interpolate }: I18nMessageFunction) =>
      interpolate([
        'The redemption notice period is your estimated wait time before you can redeem your deposit'
      ])
  }
}
